import React from 'react';
//dependency imports
import { Image, Tabs, Tab } from 'react-bootstrap';

//file imports
import BreadCrumbBar from '../Layout/Nav/BreadCrumbBar';
import marketActionsPreviewImage from '../../assets/market-actions-cover.png';
import PMTDisplay from './PMTDisplay';
import utils from '../../utils';
import CONSTANTS from '../../constants';
import MarketActionsSummaryView from './PMTChartDashboard/MarketActionsSummaryView';
import ListSkeletonLoader from '../Layout/ListSkeletonLoader';



function PMTApp({ tenantSetting, suppliers, handleShowArchived, handleLoadList, handleReload, preview=false, apiKey, updateTopList, appName, archived, options, displayOptionHandler, parentPMTList, updateListItemForItemChange, loading=false, memberList }) {

    let breadCrumbName = utils.findURLPath(tenantSetting.tenant.fields.apps, "pmt_ma")

    const handleLoadArchived = (archived = null, setView = 1) => {
        handleShowArchived(true)
        handleLoadList();
    }

    return (
        <div className="market-actions-display">
            {
                !preview ?
                    <>
                        <BreadCrumbBar
                            appName={breadCrumbName}
                        />
                        <h2 className='m-b-end-08'>{CONSTANTS.APPS_BY_NAME[breadCrumbName.toUpperCase()]}</h2>
                        <Tabs
                            defaultActiveKey={'summary'}
                            id='pmt-user-tabs'
                            className='app-tabs m-b-end-08'
                        >
                            <Tab
                                eventKey={'summary'}
                                title='Summary'
                            >
                                {
                                    !loading  && tenantSetting ? 
                                        <MarketActionsSummaryView />
                                    : <ListSkeletonLoader preview={false} />
                                }
                            </Tab>
                            <Tab
                                eventKey={'detailed-view'}
                                title='Detailed View'
                            >
                                {
                                    parentPMTList && parentPMTList.length > 0 ?
                                        <PMTDisplay 
                                            tenant={tenantSetting}
                                            memberList={memberList}
                                            suppliers={suppliers}
                                            options={options}
                                            displayOptionHandler={displayOptionHandler}
                                            apiKey={apiKey}
                                            appName={appName}
                                            handleReload={handleReload}
                                            updateTopList={updateTopList}
                                            topList={parentPMTList}
                                            loading={loading}
                                            handleListUpdate={updateListItemForItemChange}
                                            handleLoadArchived={handleLoadArchived}
                                        />
                                    : <ListSkeletonLoader preview={false} />
                                }
                            </Tab>
                        </Tabs>
                    </>
                    :
                    <div className='preview-img-div'>
                        <Image className='preview-card-img' src={appName && appName.fields && appName.fields.previewImage && appName.fields.previewImage.url ? appName.fields.previewImage.url : marketActionsPreviewImage} />
                    </div>
            }
        </div>
    )
}

export default PMTApp;