import React, { useState, useEffect } from 'react';
import { Row, Col, Button, Dropdown } from 'react-bootstrap';
import { PlusCircle, ChevronDown } from 'react-bootstrap-icons';

import ListSkeletonLoader from '../Layout/ListSkeletonLoader';
import CheckBoxDropDownSelector from '../MktActionsFilterBarComps/CheckBoxDropDownSelector';
import MarketActionsExcelExport from '../MarketActions/MarketActionsExcelExport';
import CONSTANTS from '../../constants';
import PMTTableView from './Table/PMTTableView';
import PMTKanbanView from './Kanban/PMTKanbanView';
import PMTListViewManipulator from './PMTListViewManipulator';
import PMTCreateEditItemPopup from './PMTCreateEditItemPopup';
import PMTNoItemInList from './PMTNoItemInList';
import PMTCONSTANTS from './pmtConstants';

function PMTDisplay({ tenant, memberList, suppliers, options, displayOptionHandler, apiKey, appName, handleReload, updateTopList, topList, loading, handleListUpdate }) {
    
    let [selectedListItems, setSelectedListItems] = useState([]);
    let [firstLoad, setFirstLoad] = useState(true);
    let [listViewOption, setListViewOption] = useState('2'); // '2' is Kanban
    let [sepByField, setSepByField] = useState();

    let [show, setShow] = useState(false);
    let [itemID, setItemID] = useState();

    const listViews = [
        { name: 'Table', value: '1' },
        { name: 'Kanban', value: '2' },
    ]

    const listViewObj = {
        "1": "Table",
        "2": "Kanban",
    }

    useEffect(() => {
        if (topList && topList.length > 0 && firstLoad === true) {
            alphabetizedList(topList);
            setFirstLoad(false)
        }
        //eslint-disable-next-line
    }, [topList, firstLoad])

    let isDisabled = topList.length > 0 ? false : true;

    //Alphabetizes toplist
    const alphabetizedList = (list) => {
        let alphaList = list.sort((a, b) => a.list_name.localeCompare(b.list_name));
        let listToFilter = alphaList[0].list_name;
        setSelectedListItems(listToFilter)
    }

    //Filters out only the first list item on load or the listNames that are selected.
    const filterToListsSelected = (list, selected) => {
        let retArr;
        // Leave for dev testing---------------------------------------------------------------------------
        // let selectedItems = ['test market']
        // let filteredArray = list.filter((listObj) => {
        //     return selectedItems.includes((listObj.list_name.toLowerCase()))
        // });
        //-------------------------------------------------------------------------------------------------

        //filters to show testmarket only leave for trouble shooting
        let selectedItems = selected && selected.length > 0 ? selected : ['Test Market']
        let filteredArray = list.filter((listObj) => selectedItems.includes((listObj.list_name)));

        //check if filters are applied if so display filtered data
        let listWithFilters = checkForFilters(filteredArray, options)

        if (listWithFilters && listWithFilters.length > 0) {
            retArr = listWithFilters;
        } else {
            retArr = filteredArray;
        }
        return retArr;
    }

    //separates Kanban columns by fields
    const handleShowChange = (e) => {
        setSepByField(e.value);
    }

    // handles view change
    const listViewHandle = (e) => {
        setListViewOption(e);
    }

    //gets a list of all status for filtering by dropdown
    const getStatusList = (listObj) => {
        if (listObj.hasOwnProperty('statuses')) {
            return listObj.statuses.map((listItem) => {
                return listItem.status;
            });
        }
        return [];
    }


    // Shows the modal for single item mode
    const handleShowListItemModal = (id = null) => {
        if (id) {
            setItemID(id)
        }
        if (show === true) {
            setItemID(null);
        }
        setShow(!show);
    }

    // filters for date range
    const filterInDateRange = (months, allListItems) => {
        let temp = allListItems
        let startDate = new Date()
        let startDateWithTimeStamp = startDate.getTime()
        let endDate = new Date().setMonth(startDate.getMonth() + months)

        let filteredList = temp.filter((listItem) => {
            let dueDate = new Date(listItem.due_date).getTime();
            return dueDate && dueDate >= startDateWithTimeStamp && dueDate <= endDate
        })
        return filteredList;
    }

    //Check if filters exist if so filter to selected items
    const checkForFilters = (tempArr, opts) => {
        let filteredList = [];

        if (opts['Show Archived'] === true) {
            filteredList = tempArr;
        }
        if (opts['Due in the next 6 months'] === true) {
            filteredList = filterInDateRange(6, tempArr)
        }
        if (opts['Due in the next year'] === true) {
            filteredList = filterInDateRange(12, tempArr)
        }
        return filteredList;
    }

    const getDropdownOption = (temp) => {
        const optionsArray = Object.keys(temp);
        let returnString = "";
        let filteredArray = optionsArray.filter((option) => {
            return temp[option] === true
        })
        if (filteredArray && filteredArray.length > 0) {
            for (let i = 0; i < filteredArray.length; i++) {
                if (i > 0) {
                    returnString = returnString + ", " + filteredArray[i].slice(0, 3) + "...";
                } else {
                    returnString = filteredArray[i];
                }
            }
        } else {
            returnString = 'Select...'
        }
        return returnString;
    }

    return (
        !loading && topList && tenant ?
            <div>
                {/* PMT Toolbar */}
                <Row className="markets-toolbar" sm={9} md={12}>
                    {/* Table | Kanban */}
                    <Col className='view-btns-col'>
                        <label className='dropdown-label'>View</label>
                        <Dropdown className='market-view-btns standard-dropdown dropdown-text-left'>
                            <Dropdown.Toggle>
                                {listViewObj[listViewOption]}
                                <ChevronDown />
                            </Dropdown.Toggle>
                            <Dropdown.Menu>
                                {
                                    listViews.map((opt, idx) => {
                                        return (
                                            <Dropdown.Item
                                                disabled={isDisabled}
                                                eventKey={idx}
                                                id={`opt${idx}`}
                                                name={opt.value}
                                                active={listViewOption === opt.value}
                                                onClick={(e) => listViewHandle(e.target.name)}
                                            >
                                                {opt.name}
                                            </Dropdown.Item>
                                        )
                                    })
                                }
                            </Dropdown.Menu>
                        </Dropdown>
                    </Col>
                    {/* filter-by or grouped-by dropdown */}
                    <Col className='dropdown-col'>
                        <PMTListViewManipulator
                            className="dropdown-text-left"
                            disabled={isDisabled}
                            whichView={listViewOption}
                            type="dropdown"
                            onChange={handleShowChange}
                            assigneeList={memberList}
                            statusList={getStatusList(topList)}
                        />
                    </Col>
                    {/* Checkbox "More Display Options" dropdown */}
                    <Col className='dropdown-col'>
                        <label className='dropdown-label'>More Display Options</label>
                        <CheckBoxDropDownSelector
                            className="dropdown-text-left"
                            type="dropdown"
                            options={options}
                            displayOptionHandler={displayOptionHandler}
                            labelString={getDropdownOption(options)}
                        />
                    </Col>

                    {/* Export Button*/}
                    <Col className="add-intervention-col m-b-start-03" sm={12} lg={3}>

                        <MarketActionsExcelExport
                            className='icon-btn btn-primary-lg'
                        // disabled={isDisabled}
                        // filteredMarkets={filteredList}
                        />
                        {/* Add Intervention Button*/}
                        <Button className='icon-btn surface-brand-primary btn-primary-lg m-i-start-03'
                            onClick={() => handleShowListItemModal()}
                        >
                            <PlusCircle />
                            {`Add ${PMTCONSTANTS.LIST_ITEM_NAME[appName.fields.appAlternateTitle]}`}
                        </Button>
                    </Col>
                </Row>
                {/* Intervention Display*/}
                <Row>
                    {
                        <Col className={`view-display ${CONSTANTS.MARKET_ACTIONS_REVERSE_VIEWS[listViewOption]}`}>
                            {
                                topList && topList.length > 0 ?

                                    listViewOption && listViewOption === CONSTANTS.MARKET_ACTIONS_VIEWS.KANBAN ?
                                        <PMTKanbanView
                                            listNames={selectedListItems}
                                            topList={filterToListsSelected(topList, selectedListItems)}
                                            onUpdate={handleListUpdate}
                                            separateByField={sepByField}
                                            tenant={tenant}
                                            appName={appName}
                                            assigneesToPass={memberList}
                                            handleShowListItemModal={handleShowListItemModal}
                                        />
                                        :
                                        <PMTTableView
                                            formattedList={filterToListsSelected(topList, selectedListItems)}
                                            suppliers={suppliers}
                                            appName={appName}
                                            handleShowItemModal={handleShowListItemModal}
                                            assigneesToPass={memberList}
                                            onUpdate={handleListUpdate}
                                            apiKey={apiKey}
                                        />
                                    :
                                    <PMTNoItemInList
                                        listName={topList.list_name}
                                        tenant={tenant}
                                        handleShowAddItemModal={handleShowListItemModal}
                                        dynamicItemName={'interventions'}
                                        dynamicAppName={tenant.tenant.tenantslug === 'Gavi MST' ? 'Roadmap Actions' : 'Market Actions'}
                                        appUrl='market_actions'
                                    />
                            }
                        </Col>
                    }
                </Row>
                {
                    show && show === true ?
                        <PMTCreateEditItemPopup
                            updateTopList={updateTopList}
                            tenant={tenant}
                            groupMembers={memberList}
                            show={show}
                            handleHideModal={() => setShow(false)}
                            listName={selectedListItems && selectedListItems.length > 0 ? filterToListsSelected(topList, selectedListItems)[0].list_name : null} 
                            itemID={itemID ? itemID : null}
                            mode={itemID && itemID !== null ? 'edit' : 'create'}
                            topList={topList}
                            appName={appName}
                            apiKey={apiKey}
                            clearItemID={() => setItemID()}
                            handleReload={handleReload}
                            suppliers={suppliers}
                            currentView={listViewOption}
                        />
                        : null
                }
            </div>
            :
            <ListSkeletonLoader preview={false} />
    )
}

export default PMTDisplay;